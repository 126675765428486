import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import ActiveImage from '../../../common/ActiveImage';
import { useTranslation } from 'react-i18next';

export default function ApiStatusRow({apiLabel, apiVersionStr, apiCheckMethod, refresh}) {
    const [isFetching, setIsFetching] = useState(false);
    const [apiStatus, setApiStatus] = useState(false);
    const { t } = useTranslation();

    useEffect( () => {
        setIsFetching(true);
        const waitForStatus = async() =>{
            setApiStatus( await apiCheckMethod());
            setIsFetching(false);
        }
        waitForStatus();        
        // eslint-disable-next-line
    }, [refresh]);

    return (
        <Table.Row>
            <Table.Cell >{apiLabel}</Table.Cell>
            <Table.Cell >{apiVersionStr}</Table.Cell>
            <Table.Cell >
                {isFetching &&
                <span>{t('common.loading')}</span>
                }
                {!isFetching &&
                <ActiveImage active={apiStatus} />
                }
            </Table.Cell>
        </Table.Row>
    );
}
