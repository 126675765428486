import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import CenterMapOnUserPosition from './CenterMapOnUserPosition';
import 'leaflet/dist/leaflet.css';
import Environment from '../../../../misc/helper/environmentHelper';
import { translateString } from '../../../../i18n/utils';

/**
 * Karten Voransicht für Tile Services
 * Zentrierung über Environment und ggf Benutzerdefiniert über CenterMapOnUserPosition
 * @param {*} height Höhe der Karten Ansicht als css Wert z.B. '200px'
 * @param {*} width  Breite der Karten Ansicht als css Wert z.B. '200px'
 * @param {*} url Karten Url
 * @returns 
 */
const MapPreviewTile = ({height, width, url}) => {

  const userPosition = Environment.MAP_CENTER;//[51.1657, 10.4515]);

  if(!url)return;
  return (
    <div style={{ height: height, width: width }}>
        <label>{translateString('maps.preview', 'Preview')}</label>
        <MapContainer center={userPosition} zoom={Environment.MAP_ZOOM} style={{ height: "100%", width: "100%" }}>
        <TileLayer
          url={url}
          layers="topp:states"
          format="image/png"
          transparent={true}
          attribution="&copy; GeoServer"
        />
        <CenterMapOnUserPosition position={userPosition} />
      </MapContainer>

    </div>
  );
}

export default MapPreviewTile;