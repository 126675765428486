import React from 'react';
import OperatorDeviceInspector from './OperatorDeviceInspector';
import ApiStatus from './ApiStatus';
import ImageStatus from './ImageStatus';
/**
 * Klasse fuer die Hauptansicht des Dashboards
 * @export
 * @param {*} props
 * @returns
 */
export default function DashboardMainModule(props) {
    return (
        <div>
            <OperatorDeviceInspector {...props} />
            <ApiStatus />
            <ImageStatus />
        </div>
    );
}
