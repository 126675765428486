import React, {useEffect, useState} from 'react';
import { MapContainer, WMSTileLayer } from 'react-leaflet';
import CenterMapOnUserPosition from './CenterMapOnUserPosition';
import Environment from '../../../../misc/helper/environmentHelper';
import { translateString } from '../../../../i18n/utils';

import 'leaflet/dist/leaflet.css';
 
/**
 * Karten Voransicht für WMS Services
 * Zentrierung über Environment und ggf Benutzerdefiniert über CenterMapOnUserPosition.
 * 
 * @param {*} height Höhe der Karten Ansicht als css Wert z.B. '200px'
 * @param {*} width  Breite der Karten Ansicht als css Wert z.B. '200px'
 * @param {*} url Karten Url
 * @returns 
 */
const MapPreviewWms = ({height, width, url}) => {
  const [wmsDetails, setWmsDetails] = useState({});

  const findParamValStr =(paramArr, key, defaultVal='')=>{
    // string Value aus dem Param Array der Url ermitteln
    const result = paramArr.find((param)=>{
        return param.toLowerCase().startsWith(key + '=');
    });

    const searchKey = key + '=';
    const startPos = searchKey.length;
    return result? result.slice(startPos, result.length) : defaultVal;
  }

  const findParamValBool =(paramArr, key, defaultVal=false)=>{
    // bool Value aus dem Param Array der Url ermitteln
    const result = paramArr.find((param)=>{
      
        return param.toLowerCase().startsWith(key + '=');
    });

    const searchKey = key + '=';
    const startPos = searchKey.length;
    return result? result.slice(startPos, result.length)==='true' : defaultVal;
  }

  const findParamValInt =(paramArr, key, defaultVal=256)=>{
    // int Value aus dem Param Array der Url ermitteln
    const result = paramArr.find((param)=>{
        return param.toLowerCase().startsWith(key + '=');
    });

    const searchKey=key+'=';
    const startPos = searchKey.length;
    return result? Number(result.slice(startPos, result.length)) : defaultVal;
  }

  useEffect(() => {
    //Hier muss die url wieder in ihre Bestandteile zerlegt werden um an die einzelnen Parameter zu gelangen:
    const startPoint = url.indexOf('?')+1;

    const URL = url.slice(0, url.indexOf('?'));

    const paramsStr = url.slice(startPoint, url.length);
    const paramArr = paramsStr.split('&');

    const wmsDetails = {
      url: URL,
      map: findParamValStr(paramArr, 'map'),
      request: findParamValStr(paramArr, 'request'),
      service: findParamValStr(paramArr, 'service'),
      layers: findParamValStr(paramArr, 'layers'),
      format: findParamValStr(paramArr, 'format'),
      transparent: findParamValBool(paramArr, 'transparent'),
      version: findParamValStr(paramArr, 'version'),
      tileSize: findParamValInt(paramArr, 'tilezize'),
      attribution: findParamValStr(paramArr, 'attribution'),
    }
    setWmsDetails(wmsDetails);
  },[url]);

  const userPosition = Environment.MAP_CENTER;

  if (!url) return <span>No Map</span>;

  return (
    <div style={{ height: height, width: width}}>
        <label>{translateString('maps.preview', 'Preview')}</label>
      {/* MapContainer mit dem initialen Mittelpunkt und Zoom-Level */}
      <MapContainer center={userPosition} zoom={Environment.MAP_ZOOM} style={{ height: "100%", width: "100%" }}>
        { wmsDetails?.url &&
          <WMSTileLayer
            key={wmsDetails.layers}
            url={wmsDetails.url}
            map={wmsDetails.map}
            request={wmsDetails.request}
            service={wmsDetails.service}
            layers={wmsDetails.layers} 
            format={wmsDetails.format}                
            transparent={wmsDetails.transparent}
            version={wmsDetails.version}         
            tileSize={wmsDetails.tileSize}
            attribution={wmsDetails.attribution}
          />
        }
        <CenterMapOnUserPosition position={userPosition} />
      </MapContainer>
    </div>
  );
};

export default MapPreviewWms;
