import React, { useState } from 'react';

const LabelRadioField = ({ options, name, onChange, selection}) => {
  const [selectedValue, setSelectedValue] = useState(selection);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div className='field'>
      {options.map((option) => (
        <label key={option.value}>
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={handleChange}
            style={{marginRight:'10px'}}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default LabelRadioField;