import { quickGet } from '../API';
import { PING_IMPORT, PING_EXPORT, PING_ADMINISTARATION, PING_APP } from '../paths';

export function getPingImport() {
    return quickGet(PING_IMPORT);
}

export function getPingExport() {
    return quickGet(PING_EXPORT);
}

export function getPingAdministration() {
    return quickGet(PING_ADMINISTARATION);
}

export function getPingApp() {
    return quickGet(PING_APP);
}