import React, {useState} from 'react';
import { Button, Icon, Table, Form } from 'semantic-ui-react';
import CustomPanel from '../../../common/CustomPanel';
import { useTranslation } from 'react-i18next';
import { isImportApiRunning, isExportApiRunning, isAppApiRunning, isAdministrationApiRunning } from '../../../../actions/domainActions/commonActions';
import Environment from '../../../../misc/helper/environmentHelper';
import ApiStatusRow from './ApiStatusRow';

export default function ApiStatus() {
    const { t } = useTranslation();
    const [refreshCount, setRefreshCount] = useState(0);
    const refreshApiStatus = () => {
        setRefreshCount( refreshCount+1);
    }

    const renderRefreshButton = () => {

        return (
            <Button
                icon={true}
                style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10 }}
                labelPosition='left'
                onClick={refreshApiStatus}
                disabled={false}
            >
                <Icon name='refresh' />
                Refresh
            </Button>
        );
    };

    return (
        <CustomPanel
            title={t('api.header', 'Server BackendXX')}
            loader={false}
            rightContent={() => renderRefreshButton()}
            description={t(
                'api.description',
                'Übersicht API Status XX',
            )}
        >
            <Form>
                <Table celled unstackable striped style={{ width: '600px' }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Api</Table.HeaderCell>
                            <Table.HeaderCell>Version</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <ApiStatusRow apiLabel='ImportApi' apiVersionStr={Environment.VERSION_IMPORT_API} apiCheckMethod={isImportApiRunning} refresh={refreshCount}/>
                        <ApiStatusRow apiLabel='ExportApi' apiVersionStr={Environment.VERSION_EXPORT_API} apiCheckMethod={isExportApiRunning} refresh={refreshCount}/>
                        <ApiStatusRow apiLabel='AppApi' apiVersionStr={Environment.VERSION_APP_API} apiCheckMethod={isAppApiRunning} refresh={refreshCount}/>
                        <ApiStatusRow apiLabel='AdministratorApi' apiVersionStr={Environment.VERSION_ADMINISTRATOR_API} apiCheckMethod={isAdministrationApiRunning} refresh={refreshCount}/>
                    </Table.Body>
                </Table>
            </Form>
            </CustomPanel>
    );
}
