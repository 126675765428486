import React from 'react';
import { Table, Form } from 'semantic-ui-react';
import CustomPanel from '../../../common/CustomPanel';
import { useTranslation } from 'react-i18next';
import Environment from '../../../../misc/helper/environmentHelper';
import ImageStatusRow from './ImageStatusRow';
export default function ImageStatus() {
  
    const { t } = useTranslation();
   
    return (
        <CustomPanel
            title={ t('image.header', 'Server Backend') }
            loader={false}
            description={ t('image.description', 'Übersicht API Status') }
        >
            <Form>
                <Table celled unstackable striped style={{ width: '600px' }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Image</Table.HeaderCell>
                            <Table.HeaderCell>Version</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <ImageStatusRow imageLabel='Ui' imageVersionStr={Environment.VERSION_ADMINISTRATOR_UI} />
                        <ImageStatusRow imageLabel='MongoDb' imageVersionStr={Environment.VERSION_MONGODB} />
                        <ImageStatusRow imageLabel='Grafana' imageVersionStr={Environment.VERSION_GRAFANA} />
                        <ImageStatusRow imageLabel='Loki' imageVersionStr={Environment.VERSION_LOKI} />
                    </Table.Body>
                </Table>
            </Form>
            </CustomPanel>
    );
}
