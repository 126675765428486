import React, { useEffect, useState } from 'react';

import AdvancedModal from '../../../common/AdvancedModal';
import MapsForm from './MapsForm';
import * as mapsModel from '../../../../models/maps';
import {isEmpty} from '../../../../misc/utils';

export default function MapsModal({ icon, text, buttonText, submitCallback, maps = mapsModel.maps,  checkMaps}) {
    const [_maps, setMaps] = useState(maps);
    const [isValidForm, setIsValidForm] = useState(!isEmpty(maps.title) && !isEmpty(maps.url));
    const [shouldCloseModal, setCloseModal] = useState(false);
    
    useEffect(() => {
        const isValid = !isEmpty(_maps.title) && !isEmpty(_maps.url);
        setIsValidForm(isValid);
    }, [_maps]);

    useEffect(() => {

    }, [isValidForm]);
    return (
        <AdvancedModal
            headerIcon={icon}
            headerText={text}
            buttonIcon={icon}
            buttonText={buttonText}
            open={!shouldCloseModal}
            isValidForm={isValidForm}
            submitDisabled={!isValidForm}
            submitCallback={() => {
                setCloseModal(true);
                if (isValidForm) {                    
                    
                    submitCallback(_maps);
                }
            }}
            cancelCallback={() => {
                setMaps(maps);
                setIsValidForm(true);
            }}
        >
            <MapsForm
                onChange={(key, val) => setMaps({ ..._maps, [key]: val })}
                onChangeTwoFields={(key1, val1, key2, val2) => setMaps({ ..._maps, [key1]: val1, [key2]: val2 })}
                maps={_maps}
                isValidForm={isValidForm}
                setIsValidForm={setIsValidForm}
                checkMaps ={checkMaps}
            />
        </AdvancedModal>
    );
}
