import axios from 'axios';
import Environment from '../misc/helper/environmentHelper';

const env = Environment.apiNodeEnv;
// const hostName = window.location.hostname;
const { apiUrl } = Environment;

const getUrl = () => {
    if (env === 'production') {
        return window.location.origin;
    }
    return apiUrl;
};

const axiosInstance = axios.create({
    baseURL: getUrl(),
    responseType: 'json',
});

const quickAxiosInstance = axios.create({
    baseURL: getUrl(),
    responseType: 'json',
    timeout:500
});

export function setTokenHeader(token) {
    axiosInstance.defaults.headers.common['x-access-token'] = token;
    // Um abgelaufenes Token zu testen:
    // axiosInstance.defaults.headers.common['x-access-token'] = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXIiOnsiY3JlYXRlIjp0cnVlLCJyZWFkIjp0cnVlLCJ1cGRhdGUiOnRydWUsImRlbGV0ZSI6dHJ1ZX0sImN1c3RvbWVyIjp7ImNyZWF0ZSI6dHJ1ZSwicmVhZCI6dHJ1ZSwidXBkYXRlIjp0cnVlLCJkZWxldGUiOnRydWV9LCJvcGVyYXRvciI6eyJjcmVhdGUiOnRydWUsInJlYWQiOnRydWUsInVwZGF0ZSI6dHJ1ZSwiZGVsZXRlIjp0cnVlfSwiZGV2aWNlIjp7ImNyZWF0ZSI6dHJ1ZSwicmVhZCI6dHJ1ZSwidXBkYXRlIjp0cnVlLCJkZWxldGUiOnRydWV9LCJhY3RpdmUiOnRydWUsIl9pZCI6IjYyZmUyNDQwM2JmZjU3NTdiNjJlNDBmZCIsIm1haWwiOiJhZG1pbkBpYmFrLmRlIiwicGFzc3dvcmQiOiIkMmEkMTAkUHEyUjRPbjA5eFFYTHZHb29JWS5VZWZmb3RzcDRWYUNBNTFGNWV3UXhWUG93VjR0V1ZnZGEiLCJzdXJuYW1lIjoiQWRtaW4iLCJmb3JuYW1lIjoiU3VwZXIiLCJfX3YiOjAsInBob25lIjoiKDA0MzEpIDcwIDcyIC0gMzMzIn0sImlhdCI6MTY3ODY4ODcwMiwiZXhwIjoxNjc4NzIxMTAyfQ.OZODObkgSQeL97mWDpjK_bIvUp1bAE8iCwshj7L16Z8';
}

/**
 * Wrapper fuer ein GET in axios
 * @param  {String} path   Der Pfad zur Resource
 * @param  {Object} header Objekt mit den Custom Headern
 * @return {Promise}       Den axios Promise
 */
export function get(path, header = {}) {
    return axiosInstance.get(path, header);
}

/**
 * Wrapper fuer ein GET in axios
 * @param  {String} path   Der Pfad zur Resource
 * @param  {Object} header Objekt mit den Custom Headern
 * @return {Promise}       Den axios Promise
 */
export function quickGet(path, header = {}) {
    return quickAxiosInstance.get(path, header);
}
/**
 * Wrapper fuer ein POST in axios
 * @param  {String} path    Der Pfad zur Resource
 * @param  {Object} payload Das Objekt das bei der Anfrage an die Resource mitgesendet werden soll
 * @param  {Object} header  Objekt mit den Custom Headern
 * @return {Promise}        Den axiso Promise
 */
export function post(path, payload, header = {}) {
    return axiosInstance.post(path, payload, header);
}

/**
 * Wrapper fuer ein DELETE in axios
 * @param {String} path Der Pfad zur Resource
 * @param {Object} header Objekt mit den Custom Headern
 * @return {Promise} Den axios Promise
 */
export function del(path, header = {}) {
    return axiosInstance.delete(path, header);
}
