import React from 'react';
import { Table } from 'semantic-ui-react';

export default function ImageStatusRow({imageLabel, imageVersionStr}) {
     
    return (
        <Table.Row>
            <Table.Cell>{imageLabel}</Table.Cell>
            <Table.Cell>{imageVersionStr}</Table.Cell>
        </Table.Row>
    );
}
