import React, { useState} from 'react';
import { useMap  } from 'react-leaflet';
import {COLOR_SET} from '../../../../misc/const';
import { translateString } from '../../../../i18n/utils';
import { getCoordFromStr, isNumvalue } from '../../../../misc/utils';

/**
 * Zentrierung der Karte über vorgegebene Koordinaten(ausgelesen aus den Environments der Kundenauslieferung), 
 * nachträglich änderbar über Eingabefeld oder Browserkoordinate wenn der Standortzugriff erlaubt ist.
 * Zusätzlich zur Koordinate ist hier auch der Zoomfaktor mit änderbar
 * (Es hat sich gezeigt das bestimmte Karten erst ab einem bestimmten Zoom sichtbar werden)
 * So ist es möglich das neben der übergabe der Werte aus der Konfiguration auch eigene Werte verwendet 
 * werden können falls Karten außerhalb verwendet werden sollen 
 * (Eigentlich nur interessant für die Entwicklung um zwischen unterschiedlichen Orten Karten verwenden zu können)
 * @param {*} position position Positionskoordinate [lat, lon] z.B. London: [51.505, -0.09]
 * @returns 
 */
const CenterMapOnUserPosition =({position}) =>{
  const[userPos, setUserPos] = useState(position); //Benutzerdefinierte Zentrierung
  
  const map = useMap();
  const[zoom, setZoom] = useState(map.getZoom()); 
  
  const handleZoomVal = (value) => {
    if(isNumvalue(value)){
      setZoom(value);
    }
  }
  const handleOnSelectBrowserkoordinates = () => {
    // Browserkoordinate in das Eingabefeld übertragen, wenn der Standortzugriff erlaubt ist.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // Setze die Position auf die ermittelten Koordinaten
          setUserPos([latitude, longitude]);
          map.setView([latitude, longitude],zoom);
        },
        (error) => {
          console.error("Geolocation-Fehler:", error);
        }
      );
    } else {
      alert("Geolocation wird von diesem Browser nicht unterstützt.");
    }    
  }

  const handleOnSelectUserKoordinates = () => {
    // String aus Benutzerdefinierter Eingabe wieder in nummerische Koordinate überführen
    map.setView(getCoordFromStr(userPos+''), zoom);
  }

  return (
    <div style={{position:'absolute', bottom:'0px', zIndex:1000, backgroundColor: 'rgba(255, 255, 255, 0.8)'}}>
      <label htmlFor='zoomInput'>Zoom: </label><input type='text' id='zoomInput' value={zoom} onChange={(e)=>{handleZoomVal(e.target.value)}} style={{width:'35px'}}/>
      <label htmlFor='posInput'>Lat, Lon: </label><input type='text' id='posInput' value={userPos} onChange={(e)=>{setUserPos(e.target.value)}}  style={{width:'100px'}}/>
      
      <button
          className='ui blue icon web left labeled button'
          style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, backgroundColor: COLOR_SET.PRIMARY }}
          onClick={handleOnSelectUserKoordinates}
      ><i aria-hidden='true' className='check icon'></i>{translateString('common.ok', 'ok')}
      </button> 
      <button
          className='ui blue icon web left labeled button'
          style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, backgroundColor: COLOR_SET.PRIMARY }}
          onClick={handleOnSelectBrowserkoordinates}
      ><i aria-hidden='true' className='home icon'></i>&nbsp;
      </button> 
    </div>
      );
}

export default CenterMapOnUserPosition;