const { isEmpty, getCoordFromStr, getNumberFromStr } = require('../utils');
exports.apiNodeEnv = !isEmpty(process.env.NODE_ENV) ? process.env.NODE_ENV : 'dev';
exports.apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:9031';
exports.cookieName = process.env.REACT_APP_COOKIE_NAME || 'gully-administrator-ui';

exports.VERSION_MONGODB = window._env_?.VERSION_MONGODB || '';
exports.VERSION_GRAFANA = window._env_?.VERSION_GRAFANA || '';
exports.VERSION_LOKI = window._env_?.VERSION_LOKI || '';
exports.VERSION_ADMINISTRATOR_UI = window._env_?.VERSION_ADMINISTRATOR_UI || '';
exports.VERSION_ADMINISTRATOR_API = window._env_?.VERSION_ADMINISTRATOR_API || '';
exports.VERSION_APP_API = window._env_?.VERSION_APP_API || '';
exports.VERSION_IMPORT_API = window._env_?.VERSION_IMPORT_API || '';
exports.VERSION_EXPORT_API = window._env_?.VERSION_EXPORT_API || '';

exports.MAP_CENTER = getCoordFromStr(window._env_?.MAP_CENTER || '[52.4273, 10.7887]');
exports.MAP_ZOOM = getNumberFromStr(window._env_?.MAP_ZOOM || '16');
// [lat, lon]
// Test London: [51.505, -0.09]
// Test Kiel: [51.1657, 10.4515]
// Test Norderstedt: [53.7096, 10.0000]
// Test Wolfsburg: [52.4273, 10.7887]

